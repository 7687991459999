@media only screen and (min-width: 900px) {
  .navbar {
    display: flex;
    height: 140px;
    margin: auto;
    width: 100%;
    background: rgba(170, 99, 18, 0.37);
  }

  .inner-nav {
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 140px;
    margin: auto;
    color: rgb(32, 28, 28);
    font-family: proxima-nova;
    font-weight: 400;
    font-size: 30px;
    justify-content: center;
    align-items: center;
  }

  .bar-btn {
    color: rgba(19, 16, 16, 0.703);
    font-size: 40px;
    transition: color 0.3s ease-in-out;
  }
  .bar-btn:hover {
    cursor: pointer;
    color: rgb(92, 73, 43);
    transition: color 0.3s ease-in-out;
  }
  .left-nav {
    text-align: center;
    width: 20%;
  }
  .title {
    width: 60%;
    text-align: center;
    background: -webkit-linear-gradient(
      rgb(146, 19, 19, 0.7),
      rgba(15, 31, 29, 0.7),
      rgb(10, 10, 44, 0.7)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 54px;
    font-family: "Permanent Marker", cursive;
    color: white;
  }

  .heart-cart-cont {
    width: 20%;
  }

  .cart-area {
    display: flex;
    width: 50%;
    margin: auto;
    text-align: center;
  }

  .cart-icon {
    transition: color 0.3s ease-in-out;
  }
  .cart-icon:hover {
    cursor: pointer;
    color: rgb(92, 73, 43);
    transition: color 0.3s ease-in-out;
  }

  .heart-cont {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
    height: 28px;
    width: 28px;
    margin-left: -12px;
  }

  .heart-cont:hover {
    color: rgba(0, 0, 255, 0.486);
    cursor: pointer;
  }

  .heart-icon {
    color: rgba(202, 37, 37, 0.637);
  }
  .heart-icon:hover {
    color: rgba(0, 0, 255, 0.466);
  }
}
@media only screen and (max-width: 900px) {
  .navbar {
    display: flex;
    height: 140px;
    margin: auto;
    width: 100%;
    background: rgba(170, 99, 18, 0.37);
  }

  .inner-nav {
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 140px;
    margin: auto;
    color: rgb(32, 28, 28);
    font-family: proxima-nova;
    font-weight: 400;
    font-size: 30px;
    justify-content: center;
    align-items: center;
  }

  .bar-btn {
    color: rgba(19, 16, 16, 0.703);
    font-size: 24px;
    transition: color 0.3s ease-in-out;
  }
  .bar-btn:hover {
    cursor: pointer;
    color: rgb(92, 73, 43);
    transition: color 0.3s ease-in-out;
  }
  .left-nav {
    text-align: center;
    width: 20%;
  }
  .title {
    width: 60%;
    text-align: center;
    background: -webkit-linear-gradient(
      rgb(146, 19, 19, 0.7),
      rgba(15, 31, 29, 0.7),
      rgb(10, 10, 44, 0.7)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 34px;
    font-family: "Permanent Marker", cursive;
    color: white;
  }

  .heart-cart-cont {
    width: 20%;
  }

  .cart-area {
    display: flex;
    width: 70%;
    font-size: 27px;
    margin: auto;
    text-align: center;
  }

  .cart-icon {
    transition: color 0.3s ease-in-out;
  }
  .cart-icon:hover {
    cursor: pointer;
    color: rgb(92, 73, 43);
    transition: color 0.3s ease-in-out;
  }

  .heart-cont {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
    height: 28px;
    width: 28px;
    margin-left: -12px;
  }

  .heart-cont:hover {
    color: blue;
  }

  .heart-icon {
    color: rgba(202, 37, 37, 0.637);
  }
  .heart-icon:hover {
    color: blue;
  }
}
