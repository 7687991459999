.App {
  text-align: center;
  max-width: 1600px;
  margin: auto;
  transition: all 0.7s ease;
}

.Modal-Outer {
  opacity: 0.8;
  filter: brightness(50%);
  background: rgb(94, 119, 136);
  transition: opacity 0.4s ease-in-out;
}

.no-modal {
  transition: opacity 0.4s ease-in-out;
}

/* font-family: 'Amatic SC', cursive;
font-family: 'Cairo', sans-serif;
font-family: 'Permanent Marker', cursive;
font-family: 'Alegreya', serif;
font-family: 'IBM Plex Sans', sans-serif;
font-family: 'Quattrocento Sans', sans-serif;
font-family: 'Philosopher', sans-serif; */
