.Outer-Product {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  max-height: 100%;
  margin: auto;
  /* background: rgba(97, 187, 240, 0.247); */
  z-index: 50;
}

.Product-Box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 70%;
  height: 60%;
  max-height: 600px;
  min-height: 500px;
  background: white;
  z-index: 200;
  left: 0;
  right: 0;
  margin-top: 20%;

  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  max-width: 800px;
}

.Inner-Box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  width: 98%;
  height: 94%;
  max-width: 600px;
}

.big-img-box {
  max-height: 70%;
  max-width: 50%;
  overflow: hidden;
  padding: 10px;
  border: 1px solid rgba(20, 20, 97, 0.041);
  border-radius: 10px;
  background: rgba(102, 160, 155, 0.205);
}

.detail-side {
  width: 42%;
  padding: 1%;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
}

.img-itself {
  max-height: 100%;
  max-width: 100%;
}

.big-title {
  height: 10%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.big-details {
  width: 100%;
  margin: auto;
}

.descr {
  padding: 5px;
}

.outer-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.b-prod-price {
  font-size: 20px;
  width: 50%;
}

.b-prod-size {
  font-size: 20px;
  width: 50%;
}

.cart-icon-box {
  width: 40%;
  height: 40px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 255, 157, 0.322);
  border-radius: 30px;
  cursor: pointer;
}

.cart-icon-box:hover {
  background: rgba(0, 255, 157, 0.418);
}

.icons {
  font-size: 20px;
}

.icons-x {
  font-size: 24px;
  margin-right: 20px;
  margin-top: 10px;
}

.icons-x:hover {
  color: rgb(54, 54, 201);
  cursor: pointer;
}

.x-out {
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
}

.cart-icon-box:hover > .icons {
  color: rgb(54, 54, 201);
  cursor: pointer;
}
